
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-reasons::v-deep {
    background-color: $white;
    color: $black;

    .item-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        .v-card__title {
            word-break: keep-all;
        }

        .v-card__subtitle {
            flex-grow: 100;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
